import React from "react";

const Terms = () => {
  return (
    <>
      <div className="about">
        <div className="overlay">
          <h2 className="animate__animated animate__rotateInUpRight maati">
            TERMS & CONDITION
          </h2>
        </div>
      </div>

      <div className="container">
        <div className="row py-5 my-3 ">
          <div className="col-sm-12">
            <div className="">
              <h3 className="let text-center productsa">
                Terms & conditions to use our products
              </h3>
            </div>
          </div>
        </div>
        <div
          className="row  "
          data-aos="fade-right"
          data-aos-offset="20"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top"
        >
          <div className="col-sm-12">
            <div className="">
              <h3 className="rdy">TERMS AND CONDITIONS ("TERMS")</h3>
            </div>
            <div className="col-sm-11">
              <h3 className="let mt-4 last">Last updated: March 18, 2019</h3>
            </div>
          </div>
        </div>

        <div
          className="row "
          data-aos="fade-right"
          data-aos-offset="20"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top"
        >
          <div className="col-sm-12">
            <div className="col-sm-11">
              <h3 className="second">
                Please read these Terms and Conditions (“Terms”, “Terms and
                Conditions”) carefully before using the http://app.infraguard.io
                website (the “Service”) operated by InfraGuard (“us”, “we”, or
                “our”).
              </h3>
              <h3 className="second">
                Your access to and use of the Service is conditioned on your
                acceptance of and compliance with these Terms. These Terms apply
                to all visitors, users and others who access or use the Service.
              </h3>
              <h3 className="second">
                By accessing or using the Service you agree to be bound by these
                Terms. If you disagree with any part of the terms then you may
                not access the Service.
              </h3>
            </div>
          </div>
        </div>
        <div
          className="row py-3 my-3 "
          data-aos="fade-right"
          data-aos-offset="20"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top"
        >
          <div className="col-sm-12">
            <div className="">
              <h3 className="rdy">ACCOUNTS</h3>
            </div>
            <div className="col-sm-11">
              <p className="second">
                When you create an account with us, you must provide us
                information that is accurate, complete, and current at all
                times. Failure to do so constitutes a breach of the Terms, which
                may result in immediate termination of your account on our
                Service.
              </p>

              <p className="second">
                You are responsible for safeguarding the password that you use
                to access the Service and for any activities or actions under
                your password, whether your password is with our Service or a
                third-party service.
              </p>
              <p className="second">
                You agree not to disclose your password to any third party. You
                must notify us immediately upon becoming aware of any breach of
                security or unauthorized use of your account.
              </p>
            </div>
          </div>
        </div>
        <div
          className="row py-3 my-3 "
          data-aos="fade-right"
          data-aos-offset="20"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top"
        >
          <div className="col-sm-12">
            <div className="">
              <h3 className="rdy">TERMS AND CONDITIONS OF USE</h3>
            </div>
            <div className="col-sm-11">
              <p className="second">
                Our Service may contain links to third-party web sites or
                services that are not owned or controlled by InfraGuard.
              </p>

              <p className="second">
                InfraGuard has no control over and assumes no responsibility
                for, the content, privacy policies, or practices of any third
                party web sites or services. You further acknowledge and agree
                that InfraGuard shall not be responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with use of or reliance on any such
                content, goods or services available on or through any such web
                sites or services.
              </p>
              <p className="second">
                We strongly advise you to read the terms and conditions and
                privacy policies of any third-party web sites or services that
                you visit.
              </p>
            </div>
          </div>
        </div>
        <div
          className="row py-3 my-3 "
          data-aos="fade-right"
          data-aos-offset="20"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top"
        >
          <div className="col-sm-12">
            <div className="">
              <h3 className="rdy">LINKS TO OTHER WEB SITES</h3>
            </div>
            <div className="col-sm-11">
              <p className="second">
                Our Service may contain links to third-party web sites or
                services that are not owned or controlled by InfraGuard.
              </p>

              <p className="second">
                InfraGuard has no control over and assumes no responsibility
                for, the content, privacy policies, or practices of any third
                party web sites or services. You further acknowledge and agree
                that InfraGuard shall not be responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with use of or reliance on any such
                content, goods or services available on or through any such web
                sites or services.
              </p>
              <p className="second">
                We strongly advise you to read the terms and conditions and
                privacy policies of any third-party web sites or services that
                you visit.
              </p>
            </div>
          </div>
        </div>
        <div
          className="row py-3 my-3 "
          data-aos="fade-right"
          data-aos-offset="20"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top"
        >
          <div className="col-sm-12">
            <div className="">
              <h3 className="rdy">TERMINATION</h3>
            </div>
            <div className="col-sm-11">
              <p className="second">
                We may terminate or suspend access to our Service immediately,
                without prior notice or liability, for any reason whatsoever,
                including without limitation if you breach the Terms.
              </p>

              <p className="second">
                All provisions of the Terms which by their nature should survive
                termination shall survive termination, including, without
                limitation, ownership provisions, warranty disclaimers,
                indemnity and limitations of liability.
              </p>
              <p className="second">
                We may terminate or suspend your account immediately, without
                prior notice or liability, for any reason whatsoever, including
                without limitation if you breach the Terms.
              </p>
              <p className="second">
                Upon termination, your right to use the Service will immediately
                cease. If you wish to terminate your account, you may simply
                discontinue using the Service.
              </p>
              <p className="second">
                All provisions of the Terms which by their nature should survive
                termination shall survive termination, including, without
                limitation, ownership provisions, warranty disclaimers,
                indemnity and limitations of liability.
              </p>
            </div>
          </div>
        </div>
        <div
          className="row py-3 my-3 "
          data-aos="fade-right"
          data-aos-offset="20"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top"
        >
          <div className="col-sm-12">
            <div className="">
              <h3 className="rdy">GOVERNING LAW</h3>
            </div>
            <div className="col-sm-11">
              <p className="second">
                These Terms shall be governed and construed in accordance with
                the laws of Singapore, without regard to its conflict of law
                provisions.
              </p>

              <p className="second">
                Our failure to enforce any right or provision of these Terms
                will not be considered a waiver of those rights. If any
                provision of these Terms is held to be invalid or unenforceable
                by a court, the remaining provisions of these Terms will remain
                in effect. These Terms constitute the entire agreement between
                us regarding our Service, and supersede and replace any prior
                agreements we might have between us regarding the Service.
              </p>
            </div>
          </div>
        </div>
        <div
          className="row py-3 my-3 "
          data-aos="fade-right"
          data-aos-offset="20"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top"
        >
          <div className="col-sm-12">
            <div className="">
              <h3 className="rdy">CHANGES</h3>
            </div>
            <div className="col-sm-11">
              <p className="second">
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. If a revision is material we
                will try to provide at least 15 days notice prior to any new
                terms taking effect. What constitutes a material change will be
                determined at our sole discretion.
              </p>

              <p className="second">
                By continuing to access or use our Service after those revisions
                become effective, you agree to be bound by the revised terms. If
                you do not agree to the new terms, please stop using the
                Service.
              </p>
            </div>
          </div>
        </div>
        <div
          className="row py-3 my-3 "
          data-aos="fade-right"
          data-aos-offset="20"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top"
        >
          <div className="col-sm-12">
            <div className="">
              <h3 className="rdy">CONTACT US</h3>
            </div>
            <div className="col-sm-11">
              <p className="second">
                If you have any questions about these Terms, please contact us.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
