
let data = {

  footerlist1: [
  
    {
      name: "About Us",
      url: "/about-us",
    },
    {
      name: "Blog",
      url: "/Blog",
    },
    {
      name: "Resource",
      url: "/Resource",
    },

    {
      name: "Support",
      url: "/support",
    },
    {
      name: "Contact Us",
      url:"/contact-us",
    },
  ],
 
  footerlist2: [
  
    {
      name: "Terms & Conditions",
      url: "terms",
    },
    {
      name: "Data Privacy",
      url: "dataprivacy",
    },
    {
      name: "Privacy Policy",
      url: "privacy",
    },

   
  ],

  

};

export default data;
